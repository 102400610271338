<template>
  <div class="system-config-page">
    <el-table :data="list" border stripe>
      <el-table-column
        v-for="col in columns"
        :prop="col.id"
        :key="col.id"
        :label="col.label"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column label="操作" width="250" align="center">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="showEdit(scope.row)">
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="编辑" :visible.sync="visible" width="400px" @close="this.visible = false">
      <div v-if="visible">
        <div style="margin: 0 0 20px">{{ editItem.desc }}</div>
        <!-- editItem.desc.includes('版权声明') -->
        <el-input
          v-if="editItem.desc.includes('版权声明')"
          v-model="editValue"
          placeholder="请输入参数值"
          size="normal"
          type="textarea"
          :rows="10"
          clearable
        ></el-input>
        <el-input v-else v-model="editValue" placeholder="请输入参数值" size="normal" clearable>
        </el-input>
      </div>
      <span slot="footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="edit" :loading="loading">
          确定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getSystemConfigList, editSystemConfig } from "@/api/nft";

export default {
  data() {
    return {
      list: [],
      columns: [
        {
          id: "id",
          label: "ID",
        },
        {
          id: "key",
          label: "Key",
        },
        {
          id: "desc",
          label: "参数备注",
        },
        {
          id: "value",
          label: "参数值",
        },
      ],
      visible: false,
      editItem: {},
      editValue: "",
      loading: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const { data } = await getSystemConfigList(1, 10000);
        console.log(data);
        const { items = [] } = data?.data;
        this.list = [...items];
      } catch (error) {
        console.log(error);
      }
    },
    showEdit(item) {
      this.editItem = item;
      this.editValue = item.value;
      this.visible = true;
    },
    closeEdit() {
      this.visible = false;
      this.editItem = {};
      this.editValue = "";
    },
    async edit() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const success = await this.postEdit(this.editItem.id, this.editValue);
      if (success) {
        this.fetchData();
        this.closeEdit();
      }

      this.loading = false;
    },
    async postEdit(id, value) {
      try {
        const { data } = await editSystemConfig(id, value);
        console.log("edit", data);
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
  },
};
</script>

<style lang="less">
.system-config-page {
  padding: 20px;
}
</style>
